import React, { useState, useEffect, useRef } from 'react';
import { useSpeechRecognition } from 'react-speech-kit';
import axios from 'axios';
import '../css/aimeet.css';


const languageOptions = [
  { label: 'Cambodian', value: 'km-KH' },
  { label: 'Deutsch', value: 'de-DE' },
  { label: 'English', value: 'en-AU' },
  { label: 'Farsi', value: 'fa-IR' },
  { label: 'Français', value: 'fr-FR' },
  { label: 'Italiano', value: 'it-IT' },
  { label: '普通话 (简体,中国大陆)', value: 'zh' },
  { label: 'Portuguese', value: 'pt-BR' },
  { label: 'Español', value: 'es-MX' },
  { label: 'Svenska - Swedish', value: 'sv-SE' },
];

function useScrollToBottom(dependency) {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dependency]);

  return ref;
}


const AIMeet = () => {
  const [lang, setLang] = useState('en-AU');
  const [transLang, setTransLang] = useState('zh');
  const [value, setValue] = useState([]);
  const [transValue, setTransValue] = useState([]);
  const [aiValue, setAIValue] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const scrollToBottomRef1 = useScrollToBottom(value);
  const scrollToBottomRef2 = useScrollToBottom(transValue);
  const scrollToBottomRef3 = useScrollToBottom(aiValue);


  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);


  useEffect(() => {
    // 检查浏览器是否支持 getUserMedia
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      console.log('getUserMedia supported.');
    } else {
      console.log('getUserMedia is not supported on your browser!');
    }
  }, []);

  // useEffect(() => {
  //   // 在 messages 更新时滚动到底部
  //   scrollToBottom();
  // }, [value, transValue, aiValue]);

  // const scrollToBottom = () => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
  //   }
  // };

  // console.log(messagesEndRef.current);

  const startRecording = () => {
    let chunks = [];
    let recorder;
    console.log('Attempting to start recording...');
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // console.log('Permission granted');
        // const options = { mimeType: 'audio/ogg; codecs=opus' };
        recorder = new MediaRecorder(stream);
        recorder.onstart = () => {
          // console.log('Recording started');
          // setAudioURL('');
          setIsRecording(true);
        };

        recorder.ondataavailable = async e => {
          // console.log('data available:', e.data);
          chunks.push(e.data);
          const url = URL.createObjectURL(e.data);

          try {
            const arrayBuffer = await e.data.arrayBuffer();
            // 使用ArrayBuffer
            let audioData;
            audioData = [...new Uint8Array(arrayBuffer)];
            // console.log(audioData);
            axios.post('https://audio2text.jrwjb512101.workers.dev/', arrayBuffer, {
                headers: {
                  'Content-Type': 'application/octet-stream', // 表明这是一个二进制流
                }
              })
            .then(function (response) {
              // 处理成功情形
              console.log(response.data);
              setValue(prevValue => [...prevValue, response.data]);
              getTrans(response.data);
              getAI(response.data);
            })
            .catch(function (error) {
              // 处理错误情形
              console.error('Error:', error);
            });

    
          } catch (error) {
            // 处理错误
            console.error(error);
          }

        };

        recorder.onstop = () => {
          setIsRecording(false);
          // 创建一个完整的音频 blob
          // const completeBlob = new Blob(chunks, { 'type' : 'audio/ogg; codecs=opus' });
          // // 从此 Blob 中可以创建一个可下载的链接或者用于回放的音频元素
          // // 例如：创建一个URL并与audio元素关联
          // const audioURL = URL.createObjectURL(completeBlob);
        };
        // 开始录制，并且在1000ms的time slice间隔内获取数据块
        recorder.start(2000);
        setMediaRecorder(recorder);
      })
      .catch(err => {
        console.error("An error occurred: " + err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      console.log('Recording stopped');
      // 停止所有的MediaStream轨道
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
  };


  const onEnd = () => {
    // You could do something here after listening has finished
  };

  const getTrans = (value) => {

    if (lang === transLang){
      setTransValue(value);
    }else{
        let translang = transLang.includes('-') ? transLang.split('-')[0] : transLang;
        axios.get('https://translate.jrwjb512101.workers.dev?text=' + value + '&source_lang=auto&target_lang=' + translang)
        .then(response => {
            // console.log(response.data.response.translated_text)
            setTransValue(prevValue => [...prevValue, response.data.response.translated_text]);
        })
        .catch(error => {
            console.error(error);
        });
    }
  };

  const getAI = (value) => {
    axios.get('https://llm.jrwjb512101.workers.dev/?text=' + value)
      .then(response => {
        // console.log(response.data.response.response)
        setAIValue(prevValue => [...prevValue, response.data.response.response]);
      })
      .catch(error => {
        console.error(error);
      });
  };

  // getTrans('hello');

  const onResult = (result) => {
    setValue([...value, result]);
    getTrans(result);
    getAI(result);
  };

  const changeLang = (event) => {
    setLang(event.target.value);
  };

  const changeTransLang = (event) => {
    setTransLang(event.target.value);
  };

  const onError = (event) => {
    if (event.error === 'not-allowed') {
      setBlocked(true);
    }
  };

  const { listen, listening, stop, supported } = useSpeechRecognition({
    onResult,
    onEnd,
    onError,
  });

  const toggle = listening
    ? stop
    : () => {
        setBlocked(false);
        listen({ lang });
      };

  return (
    <div className='rightContainer'>
        <div className='speechContainer'>
            <form id="speech-recognition-form" className='recognition-form'>
                <h2>Speech Recognition</h2>
                {!supported && (
                <p>
                    Oh no, it looks like your browser doesn&#39;t support Speech
                    Recognition.
                </p>
                )}
                {supported && (
                <React.Fragment>
                    <p>
                    {`Click 'Listen' and start speaking.
                    SpeechRecognition will provide a transcript of what you are saying.`}
                    </p>
                    <label htmlFor="language">Language</label>
                    <select
                    form="speech-recognition-form"
                    id="language"
                    value={lang}
                    onChange={changeLang}
                    >
                    {languageOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                        {option.label}
                        </option>
                    ))}
                    </select>
                    <label htmlFor="transcript">Transcript</label>
                    <div className='transcriptContainer'>
                      <textarea
                      id="transcript"
                      name="transcript"
                      placeholder="Waiting to take notes ..."
                      value={value.join(' ')}
                      ref={scrollToBottomRef1}
                      rows={3}
                      disabled
                      />
                      <button disabled={blocked} type="button" onClick={toggle}>
                      {listening ? 'Stop' : 'Listen'}
                      </button>
                      
                      {blocked && (
                      <p style={{ color: 'red' }}>
                          The microphone is blocked for this site in your browser.
                      </p>
                      )}
                    </div>
                </React.Fragment>
                )}
            </form>
            {/* <button onClick={startRecording} disabled={isRecording}>
                        Start Recording
            </button>
            <button onClick={stopRecording} disabled={!isRecording}>
              Stop Recording
            </button> */}
        </div>
        <div className='transContainer'>
            <label htmlFor="language">Translate Language</label>
            <select
            form="speech-recognition-form"
            id="language2"
            value={transLang}
            onChange={changeTransLang}
            >
            {languageOptions.map((option) => (
                <option key={option.value} value={option.value}>
                {option.label}
                </option>
            ))}
            </select>
            <textarea
                id="translate"
                name="translate"
                placeholder="Waiting to take translate ..."
                value={transValue.join(' ')}
                ref={scrollToBottomRef2}
                rows={3}
                disabled
                />
        </div>
        <div className='AIContainer'>
            <label htmlFor="language">AI Asistaint</label>
            <textarea
                id="aianswer"
                name="aianswer"
                placeholder="Waiting AI Answer ..."
                value={aiValue.join(' ')}
                ref={scrollToBottomRef3}
                rows={3}
                disabled
                />
        </div>
    </div>
    
  );
};


export default AIMeet;
